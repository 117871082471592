<main>
  <header>

    <nav class="navbar navbar-expand-lg navbar-transparent navbar-dark bg-dark py-3" id="navbar_main">
        <div class="container">
            <a class="navbar-brand me-lg-5 parlance" href="../index.html">parlance</a>

            <button [routerLink]="['/login']" class="navbar-toggler btn btn-sm btn-white btn-circle d-print-none" style="color:#000" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation">
              Log-in
            </button>

            <div class="collapse navbar-collapse" id="navbar_main_collapse d-print-none">
                <ul class="navbar-nav align-items-lg-center me-auto">
                    <li class="nav-item ">
                        <a class="nav-link" [routerLink]="['/']">Overview</a>
                    </li>
                    <li class="nav-item" style="display: none">
                        <a class="nav-link" [routerLink]="['/how-it-works']">How it Works</a>
                    </li>
                    <li class="nav-item" style="display: none">
                        <a class="nav-link" href="../docs/alerts.html" role="button">Use Cases</a>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link" [routerLink]="['/pricing']">Pricing</a>
                    </li>
                </ul>
                <ul class="navbar-nav align-items-lg-center ms-lg-auto d-print-none d-flex">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/login']">Log-in</a>
                    </li>
                    <li class="nav-item me-0">
                        <a target="_blank" href="https://calendly.com/parlanceapp/parlance-introductory-call" class="nav-link d-lg-none" style="color: #212529;">Get a Demo</a>
                        <a target="_blank" href="https://calendly.com/parlanceapp/parlance-introductory-call" class="btn btn-sm btn-white btn-circle btn-icon d-none d-lg-inline-flex" style="color: #212529;">
                          Get a Demo
                          <span style="display: none">
                            <span class="btn-inner--icon"><svg class="svg-inline--fa fa-shopping-cart fa-w-18" aria-hidden="true" data-prefix="fas" data-icon="shopping-cart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path></svg><!-- <i class="fas fa-shopping-cart"></i> --></span>
                            <span class="btn-inner--text">Sign up now</span>
                          </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    </nav>
  </header>

  <router-outlet></router-outlet>

  <footer class="footer py-5  footer-dark d-print-none">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="pr-lg-4">
                    <h5 class="heading h6 text-uppercase font-weight-700 mb-3">Contact</h5>
                    <ul class="list-unstyled text-small">
                        <li class="d-flex py-2">
                            <div>
                                <svg class="svg-inline--fa fa-map-marker fa-w-12" aria-hidden="true" data-prefix="fas" data-icon="map-marker" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"></path></svg><!-- <i class="fas fa-map-marker"></i> -->
                            </div>
                            <div>
                                <span class="pl-3 d-inline-block">Parlance<br>
                                830 Glenwood Ave SE Suite 510-408<br>Atlanta, GA 30316</span>
                            </div>
                        </li>
                        <li class="d-flex py-2">
                            <div>
                                <svg class="svg-inline--fa fa-envelope-open fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="envelope-open" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M512 464c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V200.724a48 48 0 0 1 18.387-37.776c24.913-19.529 45.501-35.365 164.2-121.511C199.412 29.17 232.797-.347 256 .003c23.198-.354 56.596 29.172 73.413 41.433 118.687 86.137 139.303 101.995 164.2 121.512A48 48 0 0 1 512 200.724V464zm-65.666-196.605c-2.563-3.728-7.7-4.595-11.339-1.907-22.845 16.873-55.462 40.705-105.582 77.079-16.825 12.266-50.21 41.781-73.413 41.43-23.211.344-56.559-29.143-73.413-41.43-50.114-36.37-82.734-60.204-105.582-77.079-3.639-2.688-8.776-1.821-11.339 1.907l-9.072 13.196a7.998 7.998 0 0 0 1.839 10.967c22.887 16.899 55.454 40.69 105.303 76.868 20.274 14.781 56.524 47.813 92.264 47.573 35.724.242 71.961-32.771 92.263-47.573 49.85-36.179 82.418-59.97 105.303-76.868a7.998 7.998 0 0 0 1.839-10.967l-9.071-13.196z"></path></svg><!-- <i class="fas fa-envelope-open"></i> -->
                            </div>
                            <div>
                                <span class="pl-3 d-inline-block"><a href="mailto:support@parlance.app">support@parlance.app</a></span><br>
                                <span class="pl-3 d-inline-block"><a href="mailto:sales@parlance.app">sales@parlance.app</a></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2">
                <h5 class="heading h6 text-uppercase font-weight-700 mb-3">About us</h5>
                <ul class="list-unstyled text-small">
                    <li class="py-2"><a [routerLink]="['/']">Overiview</a>
                    </li>
                    <li class="py-2"><a [routerLink]="['/pricing']">Pricing</a>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-4">
                <div class="pr-lg-5">
                    <h1 class="heading h6 font-weight-700 mb-3 parlance">parlance</h1>
                    <ul class="list-unstyled">
                        <li class="py-2"><a [routerLink]="['/terms']">Terms of Service</a>
                        </li>
                        <li class="py-2"><a [routerLink]="['/privacy']">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <hr class="my-5">
    <div class="container">
        <div class="align-items-center">
            <div class="text-center">
                <span class="d-block mt-4">&copy; 2025 Parlance. All rights reserved.</span>
            </div>
        </div>
    </div>
  </footer>

</main>
